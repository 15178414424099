




















































@import "@/assets/css/project-variables";

.forecast-percent-error{
  color: $--color-danger;
  margin-top:1px;
  font-size: 20px;
}
